export class AbstractStrategy {

    _classToHide = 'hide-by-offcanvas';

    constructor(menuSelector) {
        document.addEventListener(this.getEventName(), (e) => {
            
            let offcanvasBgColor = window.getComputedStyle(e.target).backgroundColor;
            
            var mobileNavi = document.querySelector(menuSelector);
            if (mobileNavi) {
                this.handleClass(mobileNavi, offcanvasBgColor);
            }
        });
    }

    handleClass(naviElement, bgColor) {
        throw new Error("Method 'handleClass()' must be implemented.");
    }

    getEventName() {
        throw new Error("Method 'getEventName()' must be implemented.");
    }
}