import { DataTargetStrategy } from "./item-handling/DataTargetStrategy";
export class AbstractStrategy {

    scrollTo = (activeElement) => {
        throw new Error("Method 'scrollTo()' must be implemented.");
    }

    constructor() {
        if (this.constructor == AbstractStrategy) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    addListeners() {
        const containers = document.querySelectorAll(this._getItemsContainer());
        if (containers) {
            containers.forEach((container) => {
                this.initContainer(new DataTargetStrategy(container));
            });
        }
    }

    initContainer(dataSetStrategy) {
        dataSetStrategy.addShowListener(this);
        dataSetStrategy.addItemClickListeners();
    }

    _getItemsContainer() {
        throw new Error("Method '_getItemsContainer()' must be implemented.");
    }

    getEventTarget(container) {
        throw new Error("Method 'getEventTarget()' must be implemented.");
    }

    getEventName() {
        throw new Error("Method 'getEventName()' must be implemented.");
    }

    setOffset(container) {

    }
}