
export class LongerStay {

    constructor() {

        var elements = document.querySelectorAll('.longerstay');
        
        for (var i = 0; i < elements.length; i++) {
            var el = elements[i];

            if (null !== el.getAttribute('data-timeout')) {
                var timeout = el.getAttribute('data-timeout');

                var onTimeOutFnc = (target) => {
                    return () => {
                        target.classList.remove('d-none');
                        target.classList.add('d-flex');
                    };
                };

                setTimeout(onTimeOutFnc(el), timeout);
            }
        }
    }
}