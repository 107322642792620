export class SearchForm {

    constructor() {

        var teaser = document.querySelector('.search-form-teaser');
        if (teaser) {
            var link = teaser.querySelector('a');
    
            var searchform = teaser.parentNode.querySelector('.search-form');
            
            link.addEventListener('click', function() {
                teaser.classList.add('fade');
                searchform.classList.add('show');
            });
        }
    }
}