'use strict';

import { DropdownStrategy } from "./DropdownStrategy";
import { OffcanvasStrategy } from "./OffcanvasStrategy";

export class DropdownHandling {

    constructor() {
        [new DropdownStrategy(), new OffcanvasStrategy()].forEach(this.#callStrategy);
    }

    #callStrategy(strategy) {
        strategy.addListeners();
    }

    static initHandlingForContainer(dataSetStrategy) {
        [new DropdownStrategy(), new OffcanvasStrategy()].forEach((strategy) => {
            strategy.initContainer(dataSetStrategy);
        });
    }
}