export class DataTargetStrategy {

    #container;

    #onShow(strategy) {
        const dataTarget = this.#container.getAttribute('data-target');
        const targetElement = document.getElementById(dataTarget);

        // var NodeList
        var arrValueElements = Array.from(this.#container.querySelectorAll('.list-group li'));
        var activeElements = arrValueElements.filter((item) => item.getAttribute('data-value') === targetElement?.value );

        if (activeElements.length === 1) {
            arrValueElements.forEach((el) => el.classList.remove('active'));
            activeElements[0].classList.add('active');

            strategy.scrollTo(activeElements[0]);
        }
        // strategy.setOffset(this.#container);
    }

    constructor(container) {
        this.#container = container;
    }

    addShowListener(strategy) {
        strategy.getEventTarget(this.#container).addEventListener(strategy.getEventName(), (e) => {
            this.#onShow(strategy);
        });
    }

    addItemClickListeners() {
        const target = this.#container?.getAttribute('data-target');       

        let actionElements = this.#container?.querySelectorAll('.list-group li');

        if (0 !== actionElements.length) {
            actionElements.forEach((item) => {
                item.addEventListener('click', (e) => {
                    var el = e.target;

                    if (null == el.getAttribute('data-value')) {
                        var parent = el.closest('[data-value]');
                        el = parent;
                    }
                    
                    this.#onItemclick(el, target);
                });
            });
        }
    }

    #onItemclick = (el, dataTarget) => {
        
        // set value to input
        this.#setInputValue(el, dataTarget);

        // set value to valueView
        this.#setValueViewHtml(el, dataTarget);
    }

    #setInputValue(el, dataTarget) {
        const dataValue = el.getAttribute('data-value');
        const targetElement = document.getElementById(dataTarget);

        if (dataValue && targetElement) {
            targetElement.value = dataValue;

            if (null !== targetElement.oninput) {
                targetElement.oninput();
            }
        }
    }

    #setValueViewHtml(el, dataTarget) {
        const dataTitle = el.getAttribute('data-title');
        const valueViews = document.querySelectorAll('.valueView[data-target="' + dataTarget + '"]');

        if (dataTitle && valueViews) {
            valueViews.forEach((valueView) => {
                if (dataTitle.includes('...')) {
                    valueView.classList.add('initial');
                } else {
                    valueView.classList.remove('initial');
                }
    
                var span = valueView.querySelector('span');
                span.innerHTML = dataTitle;
            });
        }
    }

}