export class SetFocusOnSearch {

    constructor() {
        var offcanvas = document.getElementById('offcanvas-search');

        offcanvas?.addEventListener('shown.bs.offcanvas', () => {
            offcanvas.querySelector('[type="text"]')?.focus();
        });
    }
}

export class ToggleMobileSearch {
    constructor() {
        var toggler = document.querySelector('[data-search-toggler]');

        toggler?.addEventListener('click', (e) => {
            e.preventDefault();

            var formId = toggler.getAttribute('data-search-toggler');

            var form = document.getElementById(formId);

            toggler.classList.add('d-none');
            form.classList.remove('d-none');

            form.querySelector('[type="search"]')?.focus();
        });
    }
}