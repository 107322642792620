import { AbstractStrategy } from "./AbstractStrategy";

export class AddClassStrategy extends AbstractStrategy {

    getEventName() {
        return 'show.bs.offcanvas';
    }

    handleClass(naviElement, bgColor) {
        naviElement.classList.add(this._classToHide);

        naviElement.style.backgroundColor = bgColor;
    }
}