export class CorventisInfo {

    static addTriggerHandler = (trigger) => {
        
        var targetContainer = document;

        trigger.addEventListener('click', (e) => {
            e.stopPropagation();

            var dropdowns = Array.from(document.querySelectorAll('.dropdown-menu.show'));
            dropdowns.forEach((dropdown) => {
                dropdown.classList.remove('show');
            });

            var target = trigger.getAttribute('data-bs-target');
            var container = trigger.getAttribute('data-target-container');

            if (null !== container) {
                targetContainer = trigger.closest(container);
            }

            var targetElement = targetContainer.querySelector(target);
            if (targetElement) {
                targetElement.classList.add('show');

                // Create the event
                var event = new CustomEvent("show.bs.dropdown", { "target": targetElement });

                // Dispatch/Trigger/Fire the event
                targetElement.parentNode.dispatchEvent(event);
            }
        });
    }

    constructor() {
        var arrayTriggers = Array.from(document.querySelectorAll('[data-bs-toggle="corventis-info"]'));

        arrayTriggers.forEach(CorventisInfo.addTriggerHandler);

        document.addEventListener('click', () => {
            var targets = Array.from(document.querySelectorAll('[data-document-close="true"]'));

            targets.forEach((target) => {
                target.classList.remove('show');
            });
        });
    }
    
}