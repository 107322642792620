export class DynamicTop {

    pageYOffset = 0;

    constructor() {

        var elements = Array.from(document.querySelectorAll('[data-dynamic-max]'));

        window.addEventListener('scroll', () => {
            var scrollPosition = window.pageYOffset;
    
            var delta = 1;
    
            if (scrollPosition < this.pageYOffset) {
                delta = -1;
            }
    
            this.pageYOffset = scrollPosition;
            var maxScroll = document.body.offsetHeight - window.innerHeight;
            var proz = scrollPosition * 100 / maxScroll;
    
    
            var onlyVisible = elements.filter((element) => !(element.offsetParent === null));
            onlyVisible.forEach((element) => {
                var max = element.getAttribute('data-dynamic-max');
                var min = element.getAttribute('data-dynamic-min');
    
                var isPercent = /\d+%/.test(max);
                var isPX = /\d+px/.test(max);
                
                var unit = '';
                if (isPercent && !isPX) {
                    // handle percent
                    unit = '%';
                }
    
                if (isPX && !isPercent) {
                    // handle PX
                    unit = 'px';
                }
    
                var minValue = parseInt(min);
    
                var diffs = parseInt(max) - minValue; 
                var interpolated  = diffs * proz / 100;
                var topValue = interpolated + minValue;
                
                element.style.setProperty('top', topValue + unit, 'important');
            });
        });
    }
}