/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// start the Stimulus application
import "../bootstrap";

// import * as bootstrap from "bootstrap";
// Window.prototype.bootstrap = bootstrap;

import { Dropdown, Collapse, Offcanvas, Carousel, Modal, Tab } from "bootstrap";
var bootstrap = {
  Dropdown: Dropdown,
  Collapse: Collapse, 
  Carousel: Carousel,
  Offcanvas: Offcanvas,
  Tab: Tab,
  Modal: Modal,
};

Window.prototype.bootstrap = bootstrap;

import { Cursor } from "./cursor/cursor";
new Cursor();

import { SplittingInit } from "./splitting/splitting-init";
new SplittingInit();

import { LongerStay } from "./longerstay/longerstay";
new LongerStay();

import { SearchForm } from "./searchform/searchform";
new SearchForm();

import { DropdownHandling } from "./canditature/DropdownHandling";
new DropdownHandling();

import { ScrollToTop } from "./scrolltotop/ScrollToTop";
new ScrollToTop();

import { CorventisInfo } from "./canditature/CorventisInfo";
new CorventisInfo();

import { DynamicTop } from "./canditature/DynamicTop";
new DynamicTop();

import { HideMenuByOffcanvas } from "./hidemenubyoffcanvas/HideMenuByOffcanvas";
new HideMenuByOffcanvas();

import { SetFocusOnSearch, ToggleMobileSearch } from "./onsearch/onsearch";
new SetFocusOnSearch();
new ToggleMobileSearch();

import { OnlyOneFadeShow } from "./only-one-fade-show/OnlyOneFadeShow";
new OnlyOneFadeShow()

import "./lazyloading/video";

// any CSS you import will output into a single css file (app.css in this case)
import "../styles/app.scss";

const myOffcanvas = document.getElementById('offcanvas-search');

myOffcanvas.addEventListener('shown.bs.offcanvas', function () {
  myOffcanvas.querySelector('#search').focus();
})
