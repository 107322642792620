import { AbstractStrategy } from "./AbstractStrategy";

export class RemoveClassStrategy extends AbstractStrategy {

    getEventName() {
        return 'hidden.bs.offcanvas';
    }

    handleClass(naviElement, bgColor) {
        naviElement.classList.remove(this._classToHide);

        naviElement.style.removeProperty('background-color');
    }
}