export class ScrollToTop {
  #button;

  scrollFunction = (e) => {
    var max = document.documentElement.clientHeight * 0.66;
    max = 20;

    if (
      document.body.scrollTop > max ||
      document.documentElement.scrollTop > max
    ) {
      this.#button?.parentNode?.classList.add("d-flex");
    } else {
      this.#button?.parentNode?.classList.remove("d-flex");
    }
  };

  onButttonClick = (e) => {
    // For Safari
    document.body.scrollTop = 0;

    // For Chrome, Firefox, IE and Opera
    document.documentElement.scrollTop = 0;
  };

  constructor() {
    this.#initButton();
    this.#addWindowOnScrollEvent();
  }

  #initButton() {
    this.#button = document.getElementById("scrollToTop");
    this.#button?.addEventListener("click", this.onButttonClick);
  }

  #addWindowOnScrollEvent() {
    window.addEventListener("scroll", this.scrollFunction);
  }
}
