import { AbstractStrategy } from "./AbstractStrategy";

export class DropdownStrategy extends AbstractStrategy {

    scrollTo = (activeElement) => {
        setTimeout(() => {
            activeElement.closest('.list-group').scrollTo({
                top: this.#findPos(activeElement) - 14,
                behavior: 'smooth'
            });
        }, 100);
    }

    _getItemsContainer() {
        return '.dropdown-menu';
    }

    getEventTarget(container) {
        return container.parentNode;
    }

    getEventName() {
        return 'show.bs.dropdown';
    }

    setOffset(container) {
        var dropdownTriggersInParent = Array.from(container.parentNode.querySelectorAll('[data-bs-toggle="dropdown"]'));
        
        var valueView = container.previousElementSibling;
        var yOffset = -1 * valueView.clientHeight - 17;

        if (dropdownTriggersInParent.length > 1) {
            yOffset = 0;
        }
        
        var popperExtendsConfig = {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [1, yOffset]
                    }
                }
            ]
        };

        var dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(valueView);

        var config = {
            ...dropdownInstance._config.popperConfig,
            ...popperExtendsConfig
        };

        // dropdownInstance._config.popperConfig = config;
    }

    #findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
            return obj.offsetTop;
        }
        return curtop;
    }
}