'use strict';

import Splitting from "splitting";

export class SplittingInit {

    windowOnLoad = () => {
        this.callFunctionforEachEtem((item) => { item.classList.add('read'); });
    }

    constructor() {
        this.activateSplitting();

        window.onload = this.windowOnLoad;

        new Splitting();
    }

    callFunctionforEachEtem(callback) {
        var textsWithEffect = document.getElementsByClassName('stagger-it');

        for(var i = 0; i < textsWithEffect.length; i++) {
            callback(textsWithEffect[i]);
        }
    }

    activateSplitting() {
        this.callFunctionforEachEtem((item) => { item.setAttribute('data-splitting', '');});
    }
}