import { AbstractStrategy } from "./AbstractStrategy";

export class OffcanvasStrategy extends AbstractStrategy {

    scrollTo = (activeElement) => {
        activeElement.closest('.offcanvas-body').scrollTo({
            top: this.#findPos(activeElement) - 20,
            behavior: 'smooth'
        });
    }

    _getItemsContainer() {
        return '.offcanvas';
    }

    getEventTarget(container) {
        return container;
    }

    getEventName() {
        return 'show.bs.offcanvas';
    }

    #findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return curtop;
        }
        return curtop;
    }
}