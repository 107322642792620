export class OnlyOneFadeShow {

    constructor() {
        var fadeContents = Array.from(document.querySelectorAll('.fade-content'));

        fadeContents.forEach((fadeContent) => {
            var tabTrigger = fadeContent.querySelector('[data-bs-toggle="tab"]');

            if (tabTrigger) {
                tabTrigger.addEventListener('show.bs.tab', (e) => {
                    fadeContents.forEach((fadeContent) => {
                        var tabTrigger = fadeContent.querySelector('.close-tab');
    
                        if (tabTrigger) {
                            var closestTab = tabTrigger.closest('.tab-pane.show');
    
                            if (null !== closestTab) {
                                var bsTab = bootstrap.Tab.getOrCreateInstance(tabTrigger);
                                bsTab.show();
                            }
                        }
    
                    });
                });
            }
            
        });
    }
}