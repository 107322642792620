'use strict';

export class Cursor {

    isCursorHolder = (element) => {
        return (null !== element.getAttribute('data-cursor')) || (element.tagName === 'body');
    }

    setDataCursor = (dataCursorAttribute) => {
        document.body.style.setProperty('cursor', 'var(--' + dataCursorAttribute + ')');
    }

    onMouseOver = (e) => {
        if (this.isCursorHolder(e.target)) {
            this.setDataCursor(e.target.getAttribute('data-cursor'));
            return;
        }

        
        var path = e.path || (e.composedPath && e.composedPath());
        var dataCursorHolder = path?.find((element, index, array) => { return this.isCursorHolder(element); });
        
        if (dataCursorHolder?.tagName === 'body') {
            document.body.style.removeProperty('cursor');
            return;
        }

        this.setDataCursor(dataCursorHolder?.getAttribute('data-cursor'));
    }

    constructor() {
        var matches = document.querySelectorAll("*[data-cursor]");

        for(var i  = 0; i < matches.length; i += 1) {
            matches[i].addEventListener('mouseover', this.onMouseOver);
        }
    }
}